// Lib
import { EnqueueSnackbar } from 'notistack';
// Models
import UserModel, { UserModelKYCType } from '../@types/models/UserModel';
import { getModel, getModels, postModel, putModel } from './base';
import AdminModel, { NewAdminModel } from 'src/@types/models/AdminModel';

export const apiGetCurrentLoggedUser = async (
  errorEnqueueSnackbar: null | EnqueueSnackbar = null,
): Promise<null | UserModel> => {
  const model = await getModel('/user', errorEnqueueSnackbar);
  return model ? (model as UserModel) : null;
};

export const apiGetUsers = async (
  errorEnqueueSnackbar: null | EnqueueSnackbar = null,
): Promise<null | Array<UserModel>> => {
  const models = await getModels('/admin/users', errorEnqueueSnackbar);
  return models ? (models as Array<UserModel>) : null;
};

export const apiPostUsers = async (
  lastKey: null | string,
  errorEnqueueSnackbar: null | EnqueueSnackbar = null,
): Promise<null | UserModel> => {
  const model = await postModel(`/admin/get/users`, { lastKey }, errorEnqueueSnackbar);
  return model ? (model as UserModel) : null;
};

export const getTerminalListUserBase = async (
  errorEnqueueSnackbar: null | EnqueueSnackbar = null,
): Promise<null | Array<UserModel>> => {
  const models = await getModels('/cashier/getterminals', errorEnqueueSnackbar);
  return models ? (models as Array<UserModel>) : null;
};

export const apiGetLocations = async (
  errorEnqueueSnackbar: null | EnqueueSnackbar = null,
): Promise<null | Array<UserModel>> => {
  const models = await getModels('/admin/terminallocation', errorEnqueueSnackbar);
  return models ? (models as Array<UserModel>) : null;
};

export const apiGetUsersForKYCVerification = async (
  errorEnqueueSnackbar: null | EnqueueSnackbar = null,
): Promise<null | Array<UserModel>> => {
  const models = await getModels('/admin/users/kyc', errorEnqueueSnackbar);
  return models ? (models as Array<UserModel>) : null;
};

export const apiUpdateUserKyc = async (
  userId: undefined | string,
  kycVerified: UserModelKYCType,
  errorEnqueueSnackbar: null | EnqueueSnackbar = null,
): Promise<null | UserModel> => {
  const model = await postModel(
    `/admin/user/${userId}/kyc`,
    { kyc: kycVerified },
    errorEnqueueSnackbar,
  );
  return model ? (model as UserModel) : null;
};

export const apiDeclinedUserKyc = async (
  userId: undefined | string,
  kycDeclinedReason: null | string,
  kycDeclinedRemarks: null | string,
  errorEnqueueSnackbar: null | EnqueueSnackbar = null,
): Promise<null | UserModel> => {
  const model = await postModel(
    `/admin/user/${userId}/kyc/declined`,
    { kycDeclinedReason, kycDeclinedRemarks },
    errorEnqueueSnackbar,
  );
  return model ? (model as UserModel) : null;
};

export const apiUpdateUser = async (
  userId: undefined | string,
  body: Record<string, string>,
  errorEnqueueSnackbar: null | EnqueueSnackbar = null,
): Promise<null | UserModel> => {
  const model = await putModel(`/admin/user/${userId}`, body, errorEnqueueSnackbar);
  return model ? (model as UserModel) : null;
};

export const apiCreateuser = async (
  body: NewAdminModel,
  errorEnqueueSnackbar: null | EnqueueSnackbar = null,
): Promise<null | AdminModel> => {
  const model = await postModel(`/signup/admin/register`, body, errorEnqueueSnackbar);
  return model ? (model as AdminModel) : null;
};
