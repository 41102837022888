// Lib
import { EnqueueSnackbar } from 'notistack';
// Utils
import { setCookieData, deleteCookieData } from '../utils/cookie';
// Models
import AccessTokenModel from '../@types/models/AccessTokenModel';
import { postModel, EnvironmentType, setEnvironment } from './base';

const ACCEPTED_ROLES = [
  'admin',
  'pagcor',
  'finance',
  'kyc',
  'csr',
  'fairplaymx',
  'cor',
  'cashier',
  'nemoaudit',
];
const DAILY_REPORTS_REDIRECT = ['fairplaymx'];
const NEMO_REPORTS_REDIRECT = ['nemoaudit'];
const KYC_REPORTS_REDIRECT = ['kyc'];
const STREAM_REPORTS_REDIRECT = ['cor'];
const CASHIER_REPORTS_REDIRECT = ['cashier'];
// TODO Refactor me, move to API etc.
export const apiLogin = async (
  env: EnvironmentType,
  phone: string,
  password: string,
  errorEnqueueSnackbar: null | EnqueueSnackbar = null,
): Promise<null | AccessTokenModel> => {
  setEnvironment(env);
  const model = await postModel(
    '/signin/phone',
    { phone, password, userCaptcha: 'xyz', skipCaptcha: true },
    errorEnqueueSnackbar,
  );
  if (model) {
    const accessTokenModel = model as AccessTokenModel;
    // alert(accessTokenModel.roles);
    if (
      accessTokenModel &&
      accessTokenModel.roles &&
      accessTokenModel.roles.some((role: string) => ACCEPTED_ROLES.includes(role))
    ) {
      setCookieData('access_token', accessTokenModel.token, accessTokenModel.expiresInDays);
      //window.location.reload();
      if (accessTokenModel.roles.some((role: string) => DAILY_REPORTS_REDIRECT.includes(role)))
        window.location.href = 'customer/dailyreports';
      else if (accessTokenModel.roles.some((role: string) => KYC_REPORTS_REDIRECT.includes(role)))
        window.location.href = 'customer/kyc';
      else if (accessTokenModel.roles.some((role: string) => NEMO_REPORTS_REDIRECT.includes(role)))
        window.location.href = 'customer/dailyreports';
      else if (
        accessTokenModel.roles.some((role: string) => STREAM_REPORTS_REDIRECT.includes(role))
      )
        window.location.href = 'customer/streams';
      else if (
        accessTokenModel.roles.some((role: string) => CASHIER_REPORTS_REDIRECT.includes(role))
      )
        window.location.href = 'business/cashin';
      else window.location.reload();
      //return accessTokenModel;
    } else {
      if (errorEnqueueSnackbar) {
        errorEnqueueSnackbar(
          `Only user with <admin> role can use Admin Panel. Your role is ${
            accessTokenModel.roles || 'n/a'
          }`,
          {
            variant: 'error',
            autoHideDuration: 10000,
          },
        );
      }
    }
  }
  return null;
};

export const apiLogout = () => {
  deleteCookieData('access_token');
  window.location.href = '/';
};
