import { useState } from 'react';
// Lib
import { useSnackbar } from 'notistack';
// Materials.
import { useTheme } from '@mui/material/styles';
import { OverridableStringUnion } from '@mui/types';
import InputAdornment from '@mui/material/InputAdornment';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
// Components
import Iconify from './Iconify';
// API
import { apiLogin } from '../api/login';
import { EnvironmentType, ENVIRONMENT_TYPES } from '../api/base';

const LoginScreen = (): React.ReactElement => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  // State.
  const [phone, setPhone] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  // Functions.
  const isLoginButtonDisabled = () => phone.length < 6 || password.length < 3;
  const login = async (env: EnvironmentType) => apiLogin(env, phone, password, enqueueSnackbar);

  return (
    <Grid container={true} spacing={0} style={{ height: '100%' }}>
      <Grid
        item
        xs={12}
        md={6}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: theme.palette.grey[800],
        }}
      >
        <Card>
          <CardHeader
            title="Login"
            subheader="Please login using ArionPlay credentials."
            action={
              <div>
                {/* <img alt="ArionPlay Logo" src="/logo/logo_full.svg" /> */}
                <img alt="Fairplay Logo" width="40" src="/logo/fairplay-logo-180.png" />
                <Typography variant="subtitle2" textAlign="right" color="text.secondary">
                  Admin Portal
                </Typography>
              </div>
            }
          />
          <CardContent>
            <TextField
              type="tel"
              fullWidth
              value={phone}
              onChange={(event) => setPhone(event.target.value)}
              placeholder="Phone number starting from <+country_code>"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Iconify
                      icon={'eva:phone-fill'}
                      sx={{ color: 'text.disabled', width: 20, height: 20 }}
                    />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              fullWidth
              type="password"
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              placeholder="Password"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Iconify
                      icon={'eva:unlock-fill'}
                      sx={{ color: 'text.disabled', width: 20, height: 20 }}
                    />
                  </InputAdornment>
                ),
              }}
            />
          </CardContent>
          <CardActions style={{ margin: '0 1rem 1rem 1rem' }}>
            <Stack direction="row" spacing={1} sx={{ flexGrow: 1 }}>
              {(Object.keys(ENVIRONMENT_TYPES) as Array<EnvironmentType>).map(
                (env: EnvironmentType, index: number) => (
                  <Button
                    key={env}
                    color={
                      (['info', 'secondary', 'primary'][index] ||
                        'warning') as OverridableStringUnion<
                        | 'inherit'
                        | 'primary'
                        | 'secondary'
                        | 'success'
                        | 'error'
                        | 'info'
                        | 'warning'
                      >
                    }
                    fullWidth={true}
                    variant="contained"
                    disabled={isLoginButtonDisabled()}
                    onClick={() => login(env)}
                  >
                    Login to &nbsp;&lt;<b>{env.toLocaleLowerCase()}</b>&gt;
                  </Button>
                ),
              )}
            </Stack>
          </CardActions>
        </Card>
      </Grid>
      <Grid
        item={true}
        xs={12}
        md={6}
        style={{
          backgroundImage: 'url(/images/backgrounds/schilthorn-gcb1a9068b_1920.jpg)',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          display: 'flex',
          alignItems: 'flex-end',
        }}
      >
        <Box
          style={{
            width: '100%',
            color: 'white',
            padding: '1rem',
            backdropFilter: 'blur(10px)',
            backgroundColor: 'rgba(0, 0, 0, 0.61)',
          }}
        >
          <Typography variant="button" component="div">
            I DO NOT BELIEVE IN LUCK, I BELIEVE IN PROBABILITY
          </Typography>
          <Typography variant="caption">JESSE LIVERMORE</Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default LoginScreen;
