/**
 * User Context
 *
 * The React Context for Employer Portal.
 * @link https://reactjs.org/docs/context.html
 */
import { createContext } from 'react';
import UserModel from '../@types/models/UserModel';

export interface UserContextType {
  userModel?: null | UserModel;
}

const UserContext = createContext<UserContextType>({
  userModel: null,
});
export default UserContext;
