import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box, BoxProps } from '@mui/material';

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  disabledLink?: boolean;
}

export default function Logo({ disabledLink = true, sx }: Props) {
  const logo = (
    <Box sx={{ width: 220, height: 40, ...sx }}>
      {/* <img alt="ArionPlay Logo" src="/logo/logo_full.svg" /> */}
      <img alt="FairPlay Logo" width="40" src="/logo/fairplay-logo-180.png" />
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
