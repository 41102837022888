// Lib
import axios from 'axios';
import { EnqueueSnackbar } from 'notistack';
// Utils
import { getCookieData, setCookieData } from '../utils/cookie';
// Models
import BaseModel from '../@types/models/BaseModel';

type RequestType = 'GET' | 'POST' | 'PUT';

// Environments are available here:
// https://arionplay.atlassian.net/wiki/spaces/SD/pages/3735553/ArionPlay
export type EnvironmentType = 'DEV' | 'TEST' | 'PROD';
export const ENVIRONMENT_TYPES: Record<EnvironmentType, string> = {
  DEV: 'zwhrwwps22tcpnlu4u7vlzv3qi0lgyvy',
  TEST: 'u67k3nuhlcev7i5g3icdkvej6e0zernx',
  PROD: 'elzm7esxmrdrzhspbcsaoj5cba0rqqeo',
  // DEV_REPORTS: 'wr3tz22xbreq5dbabiwd2iqtyq0hbwqe',
  // PROD_REPORTS: 'l5bbotrgwgvq3v3meh347544740gdzgm',
};

export const getSelectedEnvironment = (): EnvironmentType => {
  let env = getCookieData('env') || '';
  if (!Object.keys(ENVIRONMENT_TYPES).includes(env)) {
    console.error('Can not read env from cookie');
    env = 'DEV';
  }
  return env as EnvironmentType;
};

export const setEnvironment = (env: EnvironmentType) => {
  setCookieData('env', env, 365);
};

export const getBaseURL = () => {
  const env = getSelectedEnvironment();
  return `https://${ENVIRONMENT_TYPES[env]}.lambda-url.ap-southeast-1.on.aws`;
};

export const getReportURL = () => {
  const env = getSelectedEnvironment();
  let url = 'wr3tz22xbreq5dbabiwd2iqtyq0hbwqe';
  if (env === 'PROD') url = 'l5bbotrgwgvq3v3meh347544740gdzgm';
  return `https://${url}.lambda-url.ap-southeast-1.on.aws`;
};

const DEFAULT_HEADERS = {
  'Content-Type': 'application/json',
};

const _sendRequest = async (
  requestType: RequestType,
  path: string,
  body: null | Record<string, any>,
  errorEnqueueSnackbar: null | EnqueueSnackbar,
  expectedModels: boolean,
): Promise<null | BaseModel | Array<BaseModel>> => {
  const baseUrl = getBaseURL();
  try {
    const authToken = getCookieData('access_token');
    const url = `${baseUrl}${path}`.trim();
    if (body) {
      _trimStringValues(body);
    }
    const config = {
      headers: Object.assign(
        DEFAULT_HEADERS,
        authToken && authToken !== ''
          ? {
              Authorization: 'Bearer ' + authToken,
            }
          : {},
      ),
    };
    let request;
    switch (requestType) {
      case 'GET':
        request = axios.get(url, config);
        break;
      case 'POST':
        request = axios.post(url, body, config);
        break;
      case 'PUT':
        request = axios.put(url, body, config);
        break;
    }
    if (!request) {
      console.error('Incorrect type', requestType);
    }
    const result = await request;
    // Return only 200 & model type result.
    if (result.status === 200) {
      if (expectedModels) {
        // TODO
        return result.data as Array<BaseModel>;
      } else {
        if (result.data.typename) {
          return result.data as BaseModel;
        } else {
          console.error('Not a model', result.data);
          return result.data;
        }
      }
    } else {
      console.error(`Not a 200 OK, but ${result.status}`);
    }
  } catch (error) {
    console.error(error);
    if (errorEnqueueSnackbar) {
      errorEnqueueSnackbar(`${error.message}: ${error.response?.data?.error || 'n/a'}`, {
        variant: 'error',
        autoHideDuration: 10000,
      });
    }
  }
  return null;
};

export const getModel = async (
  path: string,
  errorEnqueueSnackbar: null | EnqueueSnackbar = null,
): Promise<null | BaseModel> =>
  _sendRequest('GET', path, null, errorEnqueueSnackbar, false) as Promise<null | BaseModel>;

export const apiGetModelByType = async (
  modelId: string,
  modelType: string,
  errorEnqueueSnackbar: null | EnqueueSnackbar = null,
): Promise<null | BaseModel> => {
  const model = await getModel(`/admin/model/${modelType}/${modelId}`, errorEnqueueSnackbar);
  if (!model && errorEnqueueSnackbar) {
    errorEnqueueSnackbar(`Model ${modelType} :: ${modelId} is not available in DB`, {
      variant: 'error',
      autoHideDuration: 10000,
    });
  }
  return model ? (model as BaseModel) : null;
};

export const postModel = async (
  path: string,
  body: null | Record<string, any> = null,
  errorEnqueueSnackbar: null | EnqueueSnackbar = null,
): Promise<null | BaseModel> =>
  _sendRequest('POST', path, body, errorEnqueueSnackbar, false) as Promise<null | BaseModel>;

export const putModel = async (
  path: string,
  body: null | Record<string, any> = null,
  errorEnqueueSnackbar: null | EnqueueSnackbar = null,
): Promise<null | BaseModel> =>
  _sendRequest('PUT', path, body, errorEnqueueSnackbar, false) as Promise<null | BaseModel>;

export const getModels = async (
  path: string,
  body: null | Record<string, any> = null,
  errorEnqueueSnackbar: null | EnqueueSnackbar = null,
): Promise<null | Array<BaseModel>> =>
  _sendRequest('GET', path, body, errorEnqueueSnackbar, true) as Promise<null | Array<BaseModel>>;

const _trimStringValues = (obj: Record<string, any>) => {
  // Check if the input is an object
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  // Iterate through the object's properties
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      // Check if the property value is a string
      if (typeof obj[key] === 'string') {
        // Trim the string value
        obj[key] = obj[key].trim();
      } else if (typeof obj[key] === 'object') {
        // If the property is an object, recursively call the function
        obj[key] = _trimStringValues(obj[key]);
      }
    }
  }

  return obj;
};
