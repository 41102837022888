import { Suspense, lazy, ElementType, useState, useEffect, useCallback } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// Libs
import { useSnackbar } from 'notistack';
// Layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// Context
import UserContext from '../contexts/UserContext';
// Components
import LoadingScreen from '../components/LoadingScreen';
import LoginScreen from '../components/LoginScreen';
// Utils
import { getCookieData } from '../utils/cookie';
// API
import { apiGetCurrentLoggedUser } from '../api/user';
// Models
import UserModel from '../@types/models/UserModel';

const MainRoutesForLoggedUser = (): React.ReactElement => {
  const { enqueueSnackbar } = useSnackbar();
  const [userModel, setUserModel] = useState<UserModel | null>(null);

  const fetchCurrentUserModel = useCallback(async () => {
    const userModel = await apiGetCurrentLoggedUser(enqueueSnackbar);
    setUserModel(userModel);
  }, [enqueueSnackbar]);

  useEffect(() => {
    fetchCurrentUserModel();
  }, [fetchCurrentUserModel]);

  return (
    <div style={{ height: '100%' }}>
      <UserContext.Provider value={{ userModel }}>
        {useRoutes([
          {
            path: '/',
            element: <Navigate to="/customer/dashboard" replace />,
          },
          {
            path: '/customer',
            element: <DashboardLayout />,
            children: [
              { element: <Navigate to="/customer/dashboard" replace />, index: true },
              { path: 'dashboard', element: <Dashboard /> },
              { path: 'kyc', element: <KYCPending /> },
              { path: 'users', element: <Users /> },
              { path: 'streams', element: <Streams /> },
              { path: 'locations', element: <Locations /> },
              { path: 'deposits', element: <Deposits /> },
              { path: 'bonus', element: <Bonus /> },
              { path: 'withdraws', element: <Withdraws /> },
              { path: 'transactions', element: <Transactions /> },
              { path: 'bets', element: <Bets /> },
              { path: 'reports', element: <Reports /> },
              { path: 'dailyreports', element: <Dailyreports /> },
              { path: 'eventslist/:status', element: <EventsList /> },
              { path: 'eventsupdate', element: <NamesUpdate /> },
              { path: 'jsonupdate', element: <JsonUpdate /> },
              { path: 'events/add', element: <EventsAdd /> },
              { path: 'events/view/:id', element: <EventsView /> },
              { path: 'events/raceresult/:programId/:eventId', element: <RaceResults /> },
            ],
          },
          {
            path: '/masters',
            element: <DashboardLayout />,
            children: [
              { element: <Navigate to="/master/venueslist" replace />, index: true },
              { path: 'venueslist', element: <VenuesList /> },
              { path: 'venues/add', element: <VenuesAdd /> },
              { path: 'venues/add/:id', element: <VenuesAdd /> },
              { path: 'categorylist', element: <CategoryList /> },
              { path: 'category/add', element: <CategoryAdd /> },
              { path: 'category/add/:id', element: <CategoryAdd /> },
              { path: 'Poolslist', element: <PoolsList /> },
              { path: 'Pools/add', element: <PoolsAdd /> },
              { path: 'Pools/add/:id', element: <PoolsAdd /> },
            ],
          },
          {
            path: '/business',
            element: <DashboardLayout />,
            children: [
              { element: <Navigate to="/business/games" replace />, index: true },
              { path: 'sportevents', element: <SportEvents /> },
              { path: 'banks', element: <Banks /> },
              { path: 'games', element: <Games /> },
              { path: 'partners', element: <Partners /> },
              { path: 'providers', element: <Providers /> },
              { path: 'auditors', element: <Auditors /> },
              { path: 'deposit', element: <Deposit /> },
              { path: 'bonus', element: <Bonus /> },
              { path: 'withdraw', element: <Withdraw /> },
              { path: 'cashierterminal', element: <CashierTerminal /> },
              { path: 'balancer', element: <Balancer /> },
              { path: 'cashin', element: <Cashin /> },
              { path: 'cashout', element: <Cashout /> },
            ],
          },
          {
            path: '*',
            element: <LogoOnlyLayout />,
            children: [
              { path: '404', element: <NotFound /> },
              { path: '*', element: <Navigate to="/404" replace /> },
            ],
          },
          { path: '*', element: <Navigate to="/404" replace /> },
        ])}
      </UserContext.Provider>
    </div>
  );
};

// ----------------------------------------------------------------------

export default function Main() {
  if ([undefined, ''].includes(getCookieData('access_token'))) {
    return <LoginScreen />;
  } else {
    return <MainRoutesForLoggedUser />;
  }
}

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

// Pages.
const Auditors = Loadable(lazy(() => import('../pages/Auditors')));
const Banks = Loadable(lazy(() => import('../pages/Banks')));
const Bets = Loadable(lazy(() => import('../pages/Bets')));
const Dashboard = Loadable(lazy(() => import('../pages/Dashboard')));
const Deposits = Loadable(lazy(() => import('../pages/Deposits')));
const Games = Loadable(lazy(() => import('../pages/Games')));
const KYCPending = Loadable(lazy(() => import('../pages/KYCPending')));
const Partners = Loadable(lazy(() => import('../pages/Partners')));
const Providers = Loadable(lazy(() => import('../pages/Providers')));
const Reports = Loadable(lazy(() => import('../pages/Reports')));
const SportEvents = Loadable(lazy(() => import('../pages/SportEvents')));
const Streams = Loadable(lazy(() => import('../pages/Streams')));
const Locations = Loadable(lazy(() => import('../pages/Locations')));
const Transactions = Loadable(lazy(() => import('../pages/Transactions')));
const Users = Loadable(lazy(() => import('../pages/Users')));
const Withdraws = Loadable(lazy(() => import('../pages/Withdraws')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
const Dailyreports = Loadable(lazy(() => import('../pages/Dailyreports')));
const Deposit = Loadable(lazy(() => import('../pages/Deposit')));
const Bonus = Loadable(lazy(() => import('../pages/Bonus')));
const Withdraw = Loadable(lazy(() => import('../pages/Withdraw')));
const CashierTerminal = Loadable(lazy(() => import('../pages/CashierTerminal')));
const Balancer = Loadable(lazy(() => import('../pages/Balancer')));
const Cashin = Loadable(lazy(() => import('../pages/Cashin')));
const Cashout = Loadable(lazy(() => import('../pages/Cashout')));
const EventsList = Loadable(lazy(() => import('../pages/EventsList')));
const NamesUpdate = Loadable(lazy(() => import('../pages/NamesUpdate')));
const JsonUpdate = Loadable(lazy(() => import('../pages/JsonUpdate')));
const EventsAdd = Loadable(lazy(() => import('../pages/EventsAdd')));
const EventsView = Loadable(lazy(() => import('../pages/EventsFormView')));
const RaceResults = Loadable(lazy(() => import('../pages/RaceResult')));
const VenuesList = Loadable(lazy(() => import('../pages/VenuesList')));
const VenuesAdd = Loadable(lazy(() => import('../pages/VenuesAdd')));
const CategoryList = Loadable(lazy(() => import('../pages/CategoryList')));
const CategoryAdd = Loadable(lazy(() => import('../pages/CategoryAdd')));
const PoolsList = Loadable(lazy(() => import('../pages/pools/PoolsList')));
const PoolsAdd = Loadable(lazy(() => import('../pages/pools/PoolsFormSubmit')));
